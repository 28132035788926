import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Thermometer from 'react-thermometer-component'
import Cookies from 'universal-cookie';
//import Nav from './Nav';
import * as qs from 'query-string';
import GoogleMapReact from 'google-map-react';

const locationCoordinates = { lat: 41.820582, lng: 23.478257 };
const querystring = qs.parse(window.location.search);
const cookies = new Cookies();
const CopyApi = 'https://raw.githubusercontent.com/bearslairs/bearslairs-data/master/copy';
const UbiBotApi = 'https://api.ubibot.io/channels/13604?api_key=609210eb2306427a88d662d48ddb578d';
const languages = ['bg', 'en', 'ru'];
const lairImages = ['/lair-mama.png', '/lair-papa.png', '/lair-baba.png'];
const lairIcons = ['/lair-mama-icon.png', '/lair-papa-icon.png', '/lair-baby-icon.png'];
const bikeImages = ['/bike-rack.png', '/bike-small.png', '/bike-large.png'];
const bikeIcons = ['/bike-rack-icon.png', '/bike-small-icon.png', '/bike-large-icon.png'];

class App extends Component {
  state = {
    language: 'en',
    copy: {
      carousel: [],
      blurbs: [],
      cards: []
    },
    temperature: {
      value: 0,
      created_at: '2020-01-01T00:00:01Z'
    },
    humidity: {
      value: 0,
      created_at: '2020-01-01T00:00:01Z'
    }
  };
  anchors = {
    about: React.createRef(),
    lairs: React.createRef(),
    map: React.createRef()
  };

  componentDidMount() {
    let language = languages.includes(querystring.lang) // if the querystring lang is set, use that
      ? querystring.lang
      : languages.includes(cookies.get('lang')) // else if the cookies contain a lang, use that
        ? cookies.get('lang')
        : this.state.language; // fall back to a default
    this.setState(prevState => ({ 
      language: language,
      copy: prevState.copy
    }));
    cookies.set('lang', language, { path: '/' });
    fetch(CopyApi + '/' + language + '/home.json')
    .then(responseCopyApi => responseCopyApi.json())
    .then((copy) => {
      this.setState(prevState => ({
        language: prevState.language,
        copy: copy
      }));
    })
    .catch(console.log);
    fetch(UbiBotApi)
    .then(responseUbiBotApi => responseUbiBotApi.json())
    .then((container) => {
      if (container.result === 'success') {
        let lastValues = JSON.parse(container.channel.last_values);
        this.setState(prevState => ({
          temperature: lastValues.field1,
          humidity: lastValues.field2
        }));
      }
    })
    .catch(console.log);
  }

  render() {
    return (
    <>
      <Container id="container-logo">
        <Row>
          <Image src={'/logo.png'} className="m-auto" />
        </Row>
      </Container>
      <Navbar id="container-nav">
        <Nav className="m-auto">
          <Nav.Link onClick={() => window.scrollTo(0, this.anchors.about.current.offsetTop)}>about</Nav.Link>
          <Nav.Link onClick={() => window.scrollTo(0, this.anchors.lairs.current.offsetTop)}>prices</Nav.Link>
          <Nav.Link onClick={() => window.scrollTo(0, this.anchors.map.current.offsetTop)}>location</Nav.Link>
        </Nav>
      </Navbar>
      <Container id="container-header" fluid style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <h2>
          secure self storage in bansko<br />
          with access at your convenience
        </h2>
      </Container>
      <Container id="container-environment">
        <h3>
          real-time environment monitoring
        </h3>
        <p>
          we monitor temperature, humidity and light within the facility and publish those readings at
          &nbsp;
          <a href="https://space.ubibot.io/space/user/device/channel-id/13604">
            ubibot.io/bearslairs-bansko
          </a>,
          <br />
          so our customers can stay informed of the environmetal conditions of their self-storage.
          The latest readings taken were:
        </p>
        <Row className="justify-content-xl-center justify-content-md-center">
          <Col xl="auto" md="auto">
            <Thermometer
              theme="dark"
              value={(Math.round(this.state.temperature.value * 10) / 10)}
              max={(this.state.temperature.value * 1.3)}
              steps="3"
              format="°C"
              size="large"
              height="200"
            />
          </Col>
          <Col xl="auto" md="auto">
            <h4>temperature</h4>
            {
              new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZone: 'Europe/Sofia'
              }).format(new Date(this.state.temperature.created_at))
            }:&nbsp;
            <strong>{(Math.round(this.state.temperature.value * 10) / 10)}°C</strong>
            <h4>humidity</h4>
            {
              new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZone: 'Europe/Sofia'
              }).format(new Date(this.state.humidity.created_at))
            }:&nbsp;
            <strong>{this.state.humidity.value}%</strong>
          </Col>
        </Row>
      </Container>
      <Container id="container-about" ref={this.anchors.about} fluid>
        {
          this.state.copy.blurbs.slice(0, 1).map((blurb, blurbIndex) => (
            <Container key={blurbIndex}>
              <h3>{blurb.title}</h3>
              <Row className="justify-content-xl-center justify-content-md-center">
              {
                blurb.copy.map((paragraph, paragraphIndex) => (
                  <Col xl={(12 / blurb.copy.length)} key={paragraphIndex}>
                    <Image src={paragraph.image} />
                    <p>{paragraph.text}</p>
                  </Col>
                ))
              }
              </Row>
            </Container>
          ))
        }
      </Container>
      <Container id="container-security" fluid>
        <Container>
          <Row className="justify-content-xl-center justify-content-md-center">
            {
              this.state.copy.blurbs.slice(1, 2).map((blurb, blurbIndex) => (
                <Col xl={7} key={blurbIndex}>
                  <h3>{blurb.title}</h3>
                  {
                    blurb.copy.map((paragraph, paragraphIndex) => (
                      <p key={paragraphIndex}>
                        {paragraph}
                      </p>
                    ))
                  }
                  <LinkContainer to={'/book'}>
                    <Button size="sm">
                      book now
                    </Button>
                  </LinkContainer>
                </Col>
              ))
            }
          </Row>
        </Container>
      </Container>
      <Container id="container-lair" ref={this.anchors.lairs}>
        <h3>
          choose your lair
        </h3>
        <Row className="justify-content-xl-center justify-content-md-center">
          {
            this.state.copy.cards.slice(0, 3).map((card, cardIndex) => (
              <Col key={cardIndex}>
              <Card className="h-100">
                <Card.Header>
                  {card.title}
                </Card.Header>
                <div style={{height: '200px', backgroundImage: 'url(' + lairImages[cardIndex] + ')'}}>
                  <Image src={lairIcons[cardIndex]} alt={card.image.alt} style={{marginTop: '25px'}} />
                </div>
                <Card.Body>
                  <Card.Title>
                    {card.description.join(' ')}
                  </Card.Title>
                  <hr />
                  <ul style={{lmargin: 0, padding: 0}}>
                    {
                      card.features.map((feature, featureIndex) => (
                        <li key={featureIndex} style={{listStyleType: 'none', fontWeight: 600, margin: 0, padding: 0}}>
                          {feature.text}
                          <ul style={{lmargin: 0, padding: 0}}>
                            {
                              feature.details.map((detail, detailIndex) => (
                                <li key={detailIndex} style={{listStyleType: 'none', fontWeight: 'normal', margin: 0, padding: 0}}>
                                  {detail}
                                </li>
                              ))
                            }
                          </ul>
                        </li>
                      ))
                    }
                  </ul>
                </Card.Body>
                <Card.Footer>
                  <LinkContainer to={card.button.link}>
                    <Button size="sm">
                      {card.button.text}
                    </Button>
                  </LinkContainer>
                </Card.Footer>
              </Card>
              </Col>
            ))
          }
        </Row>
      </Container>
      <Container id="container-adaptive" fluid>
        
        <Container>
          <h3>Price changes from 2025</h3>
          <blockquote className="blockquote" style={{textAlign: 'left'}}>
            <p align="right">October 20<sup>th</sup>, 2024</p>
            <p align="left">Dear Customers,</p>
            <p align="left">Whilst planning the future of Bears Lairs Self Storage, we have come to understand that we are only in a position to continue to serve our customers if we increase prices to reflect the cost of maintaining our business. We all know that the cost of doing business everywhere has increased rather dramatically over recent tumultuous years and while we regret having to make this decision, it is actually the only feasible way we can continue to offer the storage facility and serve our customers going forward. Our own costs for maintenance, accounting, security and cctv alerting, bandwidth and storage have increased consistently in each year of operation but so far those costs have only been borne by the business without taking the form of customer price increases.</p>
            <p align="left">Having said that, we are not in an imminent crisis and thankfully, we have time to introduce pricing changes in a way that we hope will allow all of our customers to make considered decisions on how to proceed going forward. Customers making payments in 2024 at their current subscription rates, will not see a price increase until their next payment (in 2025) becomes due. For example, if you normally pay annually on December 31st, your bill for 31/Dec/2024 to 31/Dec/2025, will still reflect your current price plan, without any price increase, for the whole of 2025. Additionally, if you choose to convert your monthly plan to an annual one before the end of 2024, you will continue to enjoy your current pricing, until that plan becomes due for renewal.</p>
            <p align="left">Space at our facility has become rather scarce and in order to better serve the needs of our long term customers, we are discontinuing monthly payment plans. The majority of our customers make use of the facility for longer periods of time but the few who do not, often require higher levels of support in the short time they are with us. Most of our monthly subscribers have also communicated to us that monthly bills are difficult to keep track and stay on top of. For this reason, a three month stay and payment period, will be our minimum rental duration going forward.</p>
            <p align="left">Whilst making these changes, we are also introducing a clear policy that all customers will enjoy transparency and clarity on what each storage solution costs. We are simplifying our approach to prices in 2025 as follows:</p>
            <h4 style={{marginLeft: '50px'}}>2025 Quarterly Pricing</h4>
            <ul style={{marginLeft: '50px'}}>
              <li>Each <strong>2.5m²</strong> locker <sup><em>1m wide × 2.5m long</em></sup>, will cost <strong>BGN 600 quarterly</strong>.</li>
              <li>Each <strong>5m²</strong> locker <sup><em>2m wide × 2.5m long</em></sup>, will cost <strong>BGN 900 quarterly</strong>.</li>
              <li>Each <strong>7m²</strong> locker <sup><em>2m wide × 3.5m long</em></sup>, will cost <strong>BGN 1,100 quarterly</strong>.</li>
              <li>Each <strong>motorcycle</strong> parking bay, will cost <strong>BGN 180 quarterly</strong>.</li>
            </ul>
            <h4 style={{marginLeft: '50px'}}>2025 Annual Pricing</h4>
            <ul style={{marginLeft: '50px'}}>
              <li>Each <strong>2.5m²</strong> locker <sup><em>1m wide × 2.5m long</em></sup>, will cost <strong>BGN 2,200 annually</strong>.</li>
              <li>Each <strong>5m²</strong> locker <sup><em>2m wide × 2.5m long</em></sup>, will cost <strong>BGN 3,300 annually</strong>.</li>
              <li>Each <strong>7m²</strong> locker <sup><em>2m wide × 3.5m long</em></sup>, will cost <strong>BGN 4,000 annually</strong>.</li>
              <li>Each <strong>motorcycle</strong> parking bay, will cost <strong>BGN 600 annually</strong>.</li>
            </ul>
            <h4 style={{marginLeft: '50px'}}>20% Discount for Long Term <sup><em>(3 year)</em></sup> Annual Customers</h4>
            <p align="left">Any customer who has made use of the storage facility for at least 36 months, on any pricing plan frequency, will enjoy a discount of 20% on any or all of the annual plans they choose going forward. This discount is not available on quarterly pricing plans.</p>
            <p align="left">Discounted <strong>annual</strong> prices follow:</p>
            <ul style={{marginLeft: '50px'}}>
              <li>Each <strong>2.5m²</strong> locker <sup><em>1m wide × 2.5m long</em></sup>, will cost <strong>BGN 1,760 annually</strong>.</li>
              <li>Each <strong>5m²</strong> locker <sup><em>2m wide × 2.5m long</em></sup>, will cost <strong>BGN 2,640 annually</strong>.</li>
              <li>Each <strong>7m²</strong> locker <sup><em>2m wide × 3.5m long</em></sup>, will cost <strong>BGN 3,200 annually</strong>.</li>
              <li>Each <strong>motorcycle</strong> parking bay, will cost <strong>BGN 480 annually</strong>.</li>
            </ul>
            <h4 style={{marginLeft: '50px'}}>Summary</h4>
            <p align="left">We hope these changes and the reasons we are making them are clear. If they are not, or if you would like clarification on any issue regarding your storage, pricing, our facility or if you need help or want to speak to us about any of this, please do reach out. You can email <a href="mailto:rob@bearslairs.eu">rob@bearslairs.eu</a> or you can call us on +359 877 804 825.</p>
            <p align="left">
              Kind regards,<br />
              Rob and Olga at Bears Lairs Bansko
            </p>
          </blockquote>
        </Container>
      </Container>
      <Container id="container-bike" fluid>
        {
          this.state.copy.blurbs.slice(3, 4).map((blurb, blurbIndex) => (
            <Container key={blurbIndex}>
              <h3>{blurb.title}</h3>
              <Row className="justify-content-xl-center justify-content-md-center">
                <Col xl={8}>
                  <p>{blurb.copy[0]}</p>
                </Col>
              </Row>
            </Container>
          ))
        }
        <Container>
          <Row className="justify-content-xl-center justify-content-md-center">
            {
              this.state.copy.cards.slice(3, 6).map((card, cardIndex) => (
                <Col key={cardIndex}>
                <Card className="h-100">
                  <Card.Header>
                    {card.title}
                  </Card.Header>
                  <div style={{height: '200px', backgroundImage: 'url(' + bikeImages[cardIndex] + ')'}}>
                    <Image src={bikeIcons[cardIndex]} alt={card.image.alt} style={{marginTop: '25px'}} />
                  </div>
                  <Card.Body>
                    <Card.Title>
                      {card.description.join(' ')}
                    </Card.Title>
                    <hr />
                    <ul style={{lmargin: 0, padding: 0}}>
                      {
                        card.features.map((feature, featureIndex) => (
                          <li key={featureIndex} style={{listStyleType: 'none', fontWeight: 600, margin: 0, padding: 0}}>
                            {feature.text}
                            <ul style={{margin: 0, padding: 0}}>
                              {
                                feature.details.map((detail, detailIndex) => (
                                  <li key={detailIndex} style={{listStyleType: 'none', fontWeight: 'normal', margin: 0, padding: 0}}>
                                    {detail}
                                  </li>
                                ))
                              }
                            </ul>
                          </li>
                        ))
                      }
                    </ul>
                  </Card.Body>
                  <Card.Footer>
                    <LinkContainer to={card.button.link}>
                      <Button size="sm">
                        {card.button.text}
                      </Button>
                    </LinkContainer>
                  </Card.Footer>
                </Card>
                </Col>
              ))
            }
          </Row>
        </Container>
      </Container>
      <Container id="container-facility" fluid>
        {
          this.state.copy.blurbs.slice(4, 5).map((blurb, blurbIndex) => (
            <Container key={blurbIndex}>
              <h3>{blurb.title}</h3>
              <Row className="justify-content-xl-center justify-content-md-center">
              {
                blurb.copy.map((paragraph, paragraphIndex) => (
                  <Col xl={(12 / blurb.copy.length)} key={paragraphIndex}>
                    <Image src={paragraph.image} />
                    <p>{paragraph.text}</p>
                  </Col>
                ))
              }
              </Row>
              <Row className="justify-content-xl-center justify-content-md-center">
                <Col xl="auto" md="auto">
                  <LinkContainer to={'/book'}>
                    <Button size="sm">
                      book now
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
            </Container>
          ))
        }
      </Container>
      <Container id="container-map" ref={this.anchors.map} fluid>
        <div style={{ height: '650px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCKw8by28pNI5tlimezyyjgtXz_Nvkq2-Y' }}
            yesIWantToUseGoogleMapApiInternals={true}
            defaultCenter={locationCoordinates}
            defaultZoom={ 14 }
            onGoogleApiLoaded={({map, maps}) => {
              let marker = new maps.Marker({
                position: locationCoordinates,
                map,
                title: 'Bears Lairs, Bansko',
                description: 'secure, self-storage in bansko with access at your convenience',
                link: {
                  url: 'https://www.google.com/maps/place/Bears+Lairs/@41.8223813,23.4681867,15z/data=!4m5!3m4!1s0x0:0xadd4ea4c0b9a3216!8m2!3d41.820631!4d23.478215',
                  text: 'maps.google.com/Bears+Lairs'
                }
              });
              let infoWindow = new maps.InfoWindow({
                content: '<h4><img src="favicon-32x32.png" style="margin-right: 6px;" class="rounded-circle" />' + marker.title + '</h4><p>' + marker.description + '<br /><a href="' + marker.link.url + '">' + marker.link.text + '</a></p>'
              });
              infoWindow.open(map, marker);
              marker.addListener('click', () => {
                map.setZoom(14);
                map.setCenter(marker.getPosition());
                infoWindow.open(map, marker);
              });
            }} />
        </div>
      </Container>
      <Container id="container-footer" fluid>
        <Container>
          <Row className="justify-content-xl-center justify-content-md-center">
            <Col xl={6}>
              <a href="mailto:enquiries@bearslairs.eu">enquiries@bearslairs.eu</a>
              <hr />
              &copy; 2020, Bears Lairs EOOD, Bulgaria. All rights reserved
            </Col>
          </Row>
        </Container>
      </Container>
    </>
    );
  }
}

export default App;
